@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';
@import './assets/variables.scss';

// TODO: upload font to s3 (@Amit to upload)
@import url('https://gistcdn.githack.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css');

html,
body {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  // background-color: var(--background-light-base);
  &,
  * {
    font-family: 'Helvetica' !important;
  }
}

:root {
  --light-blue: #5978de;
  --dark-grey: #192839;
  --soft-black: #00000017;
  --charcoal-blue: #2b4460;
  --navy-blue: #1f3651;
  --neutral-black-1: #f3f3f3;
  --grey-4: #6d6e71;
  --grey-2: #cacaca;
  --light-grey: #d1d3d4;
  --green: #48d08c;
  --claimed-green-bg: #d7e8e1;
  --claimed-green-text: #09965b;
  --black: #000000;
  --header-height: 72px;
  --text-color: #000000;
  --gold-primary: #f3e6cf;
  --light-gold: #edebe8;
  --translucent-brown: #5f482112;
  --translucent-black: #00000006;
  @media (max-width: 768px) {
    --header-height: 60px;
  }
}

@media screen and (min-width: 962px) {
  pv-authentication pv-drawer .p-sidebar.p-sidebar-bottom {
    height: 450px !important;
  }
}

@media (max-width: 768px) {
  pv-authentication {
    --modalHeight: 470px !important;
  }
}

.text-primary-gold {
  color: var(--gold-primary);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

button {
  box-shadow: none !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-sidebar .p-sidebar-footer {
  padding-top: 0;
}

.auth-header {
  background-color: var(--primary-color);
  height: 64px;

  img {
    height: 50px;
  }
}

pv-authentication .p-sidebar {
  max-width: 448px !important;
  @media (max-width: 965px) {
    max-width: 100%;
  }
}

hdfc-redemption-modal {
  pv-drawer {
    .p-sidebar {
      @media (min-width: 768px) {
        max-width: 25rem;
        max-height: 312px !important;
      }
      @media (max-width: 767px) {
        max-width: 25rem;
        min-height: 23rem;
        max-height: 312px !important;
      }

      .p-sidebar-footer {
        padding: 0;
      }
    }

    .p-sidebar .p-sidebar-content {
      padding: 0;
    }
  }
}

.p-component-overlay {
  background-color: var(--black);
  opacity: 0.6;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--black);
    opacity: 0.6;
  }
}

.inner-scroll {
  margin-top: var(--header-height);
  max-height: calc(100vh - var(--header-height));
}

.gold-segment {
  --primary-600: #f3e6cf;
  --text-color: #996e46;
  --secondary-dark: #64523d;
}

.platinum-segment {
  --primary-600: #6d6b734a;
  --text-color: #6d6b73;
  --secondary-dark: #464646;
}

a {
  color: var(--primary-600);
}

.bg-primary {
  background-color: #f3e6cf;
}

.overflow-wrap-word {
  overflow-wrap: break-word;
  hyphens: auto;
}
